import Images from '../components/content/images.json';

export default function ImageGallery() {

    const _reversed = Images.reverse();
    return (
        <>
        <h1>Codeplant Event Gallery</h1>
        <div className="card flex justify-content-center">
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                    gridGap: '10px',
                }}
            >
                {Images && _reversed.map((item, index) => (
                    <img
                    onLoad={() => {
                        console.log('Image loaded');
                    }}
                        key={index}
                        src={item.itemImageSrc}
                        style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            borderRadius: '8px',
                            display: 'block',
                        }}
                        alt={`Image ${index + 1}`}
                    />
                ))}
            </div>
        </div>

        </>
    );
}
