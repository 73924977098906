
export default class AdminService {

    // the url to post the password to
    private static url = '/api/admin/login';

    // the async method to authenticate the user
    public static async authenticate(password: string): Promise<boolean> {

        if(window.location.hostname === 'localhost') {
            return true;
        }
        // the response from the server
        const response = await fetch(this.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ password })
        });

        // the data from the response
        const data = await response.json();

        // return the data
        return data;
    }
}