import React from 'react';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';

const skills = [
    { id: 1, name: 'Analytical thinking', icon: 'pi pi-chart-bar' },
    { id: 2, name: 'Creative thinking', icon: 'pi pi-pencil' },
    { id: 3, name: 'Resilience, flexibility and agility', icon: 'pi pi-refresh' },
    { id: 4, name: 'Motivation and self-awareness', icon: 'pi pi-bolt' },
    { id: 5, name: 'Curiosity and lifelong learning', icon: 'pi pi-book' },
    { id: 6, name: 'Technological literacy', icon: 'pi pi-desktop' },
    { id: 7, name: 'Dependability and attention to detail', icon: 'pi pi-check-circle' },
    { id: 8, name: 'Empathy and active listening', icon: 'pi pi-comments' },
    { id: 9, name: 'Leadership and social influence', icon: 'pi pi-users' },
    { id: 10, name: 'Quality control', icon: 'pi pi-list' },
];



const FutureSkillsChart = () => {
    return (
        <div style={{width: '100%', margin: 'auto'}}>
        <p>In it's 2023 report on the future of jobs, the World Economic Forum identified the following skills as essential in the future workplace.</p>
        <div className="skills-grid">
            {skills.map(skill => (
                <Card key={skill.id} className="skill-card">
                    <i className={skill.icon}></i><br />
                    <span>{skill.name}</span>
                </Card>
            ))}
        </div>
        </div>
    );
};

export default FutureSkillsChart;
