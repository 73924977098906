import { Card } from "primereact/card";
import { useConfig } from "../services/config.provider";
import { MonkeyConductor } from "./conductor";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import DiagnosticForm from "../learning-style-assessment";

type Props = {};

const AdminDashboard = (props: Props) => {
  const _config = useConfig();
  const start = <><img src="codeplant_images/logo_notext.png" width="75" /></>
  const end = <><Button icon="pi pi-unlock"></Button></>
  const _monkeyCallback = (data: any) => {
    
  };


const formCallback = (data: any) => {

}

  return (
    <div style={{backgroundColor:'#007C02', height: '100vh'}}>
      <Toolbar start={start}style={{padding: 4}} end={end}/>
      <div style={{display: 'flex', width: '100%', margin: 'auto'}}>
      <div style={{width: '30%'}}><MonkeyConductor _cb={_monkeyCallback} user={_config.name}/></div>
      <div style={{width: '70%'}}><DiagnosticForm onSubmit={formCallback}/></div>
      </div>

    </div>
  );
};

export default AdminDashboard;
