import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { useEffect, useState } from "react";
import Environments from "../components/content/environments.json";

const skills = [
  { id: 1, name: "Analytical thinking", icon: "pi pi-chart-bar" },
  { id: 2, name: "Creative thinking", icon: "pi pi-pencil" },
  { id: 3, name: "Resilience, flexibility and agility", icon: "pi pi-refresh" },
  { id: 4, name: "Motivation and self-awareness", icon: "pi pi-bolt" },
  { id: 5, name: "Curiosity and lifelong learning", icon: "pi pi-book" },
  { id: 6, name: "Technological literacy", icon: "pi pi-desktop" },
  {
    id: 7,
    name: "Dependability and attention to detail",
    icon: "pi pi-check-circle",
  },
  { id: 8, name: "Empathy and active listening", icon: "pi pi-comments" },
  { id: 9, name: "Leadership and social influence", icon: "pi pi-users" },
  { id: 10, name: "Quality control", icon: "pi pi-list" },
];

interface Environments {
  id: string;
  title: string;
  shortDescription: string;
  longDescription: string[];
  learningObjectives: string[];
  images: string[];
  url: string;
  screenshot: string;
  freeAccess?: boolean
}

type Props = {
  environment: Environments;
  _homeCb: (s?:string) => void;
  _environmentCb: (x: string) => void;
};

const LearningEnvironmentsMobile = (props: Props) => {
  const [_skills, setSkills] = useState([]);
  // a function to find a skill in the skils array by name
  const findSkill = (name: string) => {
    return skills.find((skill) => skill.name === name);
  };

  useEffect(() => {
    // get the skills that are in the learning objectives
    const skills_ = props.environment.learningObjectives.map((word) => {
      const skill = findSkill(word);
      if (skill) {
        return skill;
      }
    });

    setSkills(skills_ as any);
  }, [props]);

  const _footer = (item: any) => {
    return (
      <div style={{ textAlign: "justify" }}>
        <p>{item.shortDescription}</p>
      </div>
    );
  };
  return (
    <>
      <div style={{ width: "100%", margin: "auto" }}>
        <Panel
          style={{
            width: "100%",
            textAlign: "left",
            margin: "5px",
            padding: "3px",
          }}
          header={props.environment.title}
        >
          <img
            src={props.environment.images[0]}
            alt="Card"
            style={{ width: "100%", marginBottom: "10px" }}
          />
          {_footer(props.environment)}
          {props.environment.hasOwnProperty("freeAccess") && props.environment.freeAccess === true ? (
          <Button   style={{width: '100%'}} label={"Click for Preview Access"} icon="pi pi-arrow-right" onClick={() => window.open(props.environment.url)} />
        ): <Button  style={{width: '100%'}}  label={"Join AI Club to Access"} icon="pi pi-arrow-right" onClick={() => {props._homeCb('sign-up')}} />}
          <br />
        </Panel>
        <div style={{ width: "100%", paddingTop: "10px" }}>
          <div style={{ width: "100%", margin: "auto", textAlign: "justify" }}>
            <img
              src={props.environment.screenshot}
              style={{
                borderRadius: "15px",
                width: "100%",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            />
            {props.environment.longDescription.map((eachOne: string) => {
              return <p>{eachOne}</p>;
            })}
          </div>
          <div style={{ width: "100%", margin: "auto", textAlign: "justify" }}>
            <hr />
          </div>
          <br />
          <h4>Learning Objectives</h4>
          <div className="skills-grid">
            {_skills.length > 0 &&
              _skills.map((skill: any) => (
                <Card key={skill.id} className="skill-card-sm">
                  <i className={skill.icon}></i>
                  <br />
                  <span>{skill.name}</span>
                </Card>
              ))}
          </div>

          <br />

          <Button
            label="Home"
            icon="pi pi-arrow-left"
            onClick={()=>{props._homeCb()}}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

export default LearningEnvironmentsMobile;
