import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
interface FormData {
    fullName: string;
    email: string;
    cell?: string;
    option: string;
    message: string;
}

interface ContactFormProps {
    onSubmit: (data: FormData) => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ onSubmit }) => {
    const toastRef = useRef<Toast>(null);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [cell, setCell] = useState('');
    const [option, setOption] = useState('');
    const [message, setMessage] = useState('');

    const options = [
        { label: 'AI Club', value: 'AI Club' },
        { label: 'Private Online Lessons', value: 'Private Lessons' },
        { label: 'Other', value: 'Other' }
    ];

    const handleSubmit = async () => {
        const data: FormData = {
            fullName,
            email,
            cell: cell || undefined, // optional field
            option,
            message
        };
        try {
            const response = await fetch(`/api/contact/submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            toastRef.current?.show({ severity: 'info', summary: 'Success', detail: 'Message Sent', life: 3000 });
            setFullName('');
            setEmail('');
            setCell('');
            setOption('');
            setMessage('');
            return response;
        } catch (error) {
            console.error('Error submitting signup:', error);
            throw error;
        }

 
    };

    return (
        <div style={{display: 'flex'}}>
        <Toast ref={toastRef} />
            <div style={{width:"50%", margin: '20px'}}>
                <img src="/codeplant_images/logo_med.png" alt="look" style={{width:"50%",borderRadius: '15px'}}/>
            </div>
            <div className='p-fluid'  style={{width:"30%", textAlign: 'left', margin: '20px'}}>
            <div className="p-field">
                <label htmlFor="fullName">Full Name</label>
                <InputText
                    id="fullName"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                />
            </div>
            <div className="p-field">
                <label htmlFor="email">Email</label>
                <InputText
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="p-field">
                <label htmlFor="cell">Cell (Optional)</label>
                <InputText
                    id="cell"
                    value={cell}
                    onChange={(e) => setCell(e.target.value)}
                />
            </div>
            <div className="p-field">
                <label htmlFor="option">What are you interested in?</label>
                <Dropdown
                    id="option"
                    value={option}
                    options={options}
                    onChange={(e) => setOption(e.value)}
                    placeholder="Select an option"
                />
            </div>
            <div className="p-field">
                <label htmlFor="message">Message</label>
                <InputTextarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={5}
                    cols={30}
                />
            </div>
            <div className="p-field">
                <Button label="Submit" onClick={handleSubmit} />
            </div>
            </div>
        </div>
    );
};

export default ContactForm;
