import { useEffect, useRef, useState } from "react";
import AdminMonkey from "./admin-monkey";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import Markdown from "react-markdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Toast } from "primereact/toast";

interface CodeMonkeyProps {
  _cb: (data: string) => void;
  user: string;
}
export const MonkeyConductor = (props: CodeMonkeyProps) => {
  const [streamData, setStreamData] = useState<string>(
    `Hey, ${props.user}. How can I help you today?`
  );
  const toastRef = useRef<Toast>(null);
  const [_changeImage, setChangeImage] = useState<string>("");
  const [promptData, setPromptData] = useState<string>("");
  const [finished, setFinished] = useState<boolean>(false);
  const [_messages, setMessages] = useState<
    { role: string; content: string }[]
  >([]);

  const callCodeGenerationService = async (messageContent: string) => {
    setPromptData("");
    const _messagesArray = [
      ..._messages,
      { role: "user", content: messageContent },
    ];
    setMessages(_messagesArray);
    try {
      const response = await fetch("/api/ai/monkey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/event-stream", // Accept event-stream
        },
        body: JSON.stringify({
          messages: _messagesArray,
          stream: true,
          coder: false,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to connect to the text generation service");
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let streamData = "";
      while (!done) {
        const { value, done: readerDone } = (await reader?.read()) || {};
        if (readerDone) {
          setFinished(true);
          setPromptData("");
          setMessages([
            ..._messages,
            { role: "assistant", content: streamData },
          ]);
          break;
        }
        done = readerDone as any;
        const chunkValue = decoder.decode(value, { stream: !readerDone });
        const chunk = JSON.parse(chunkValue.split("data: ")[1]);
        streamData += chunk.response;
        setStreamData(streamData);
      }
    } catch (error) {
      setFinished(true);
      console.error("Error while calling text generation service:", error);
    }
  };

  const callConductorService = async (messageContent: string) => {
    setPromptData("");

    // remove the # first cahr from the string
    messageContent = messageContent.substring(1);
    const _messagesArray = [
      { role: "user", content: messageContent },
    ];

    try {
      const response = await fetch("/api/ai/conductor", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json", // Accept event-stream
        },
        body: JSON.stringify({
          messages: _messagesArray
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to connect to the text generation service");
      }
      let result = await response.json();
      setStreamData(result.result.response);

    } catch (error) {
      setFinished(true);
      console.error("Error while calling text generation service:", error);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (promptData) {
      setStreamData("Shaking the tree...🌴");
      setFinished(false);
      //  heck if running on localhost
      if (window.location.hostname === "localhost") {
        sendLocalRequest(promptData);
      } else {

        if(promptData[0] == '#'){
            callCodeGenerationService(promptData);
        } else {
          callConductorService(promptData);
        }
      }
    }
  };

  const handleQuickButtons = (s: string) => {
    setStreamData("Shaking the tree... 🌴");
    setFinished(false);
    //  heck if running on localhost
    if (window.location.hostname === "localhost") {
      sendLocalRequest(s);
    } else {
      callConductorService(s);
    }
  };

  const sendLocalRequest = async (requestData: any) => {
    setPromptData("");
    const _messagesArray = [
      {
        role: "system",
        content:
          "You are an intelligent and helpful admin assistant for Codeplant. Since you are running locally, you don't have access to real data so you just fake coherent responses.",
      },
      ..._messages,
      { role: "user", content: requestData },
    ];
    setMessages([..._messages, { role: "user", content: requestData }]);
    try {
      const response = await fetch(
        "http://localhost:1234/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ messages: _messagesArray, stream: true }),
        }
      );

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let streamData_ = "";
      while (!done) {
        const { value, done: readerDone } = (await reader?.read()) || {};
        if (readerDone) {
          setFinished(true);
          setPromptData("");
          setMessages([
            ..._messages,
            { role: "assistant", content: streamData },
          ]);
          break;
        }
        done = readerDone as any;
        const chunkValue = decoder.decode(value, { stream: !readerDone });
        const chunk = JSON.parse(chunkValue.split("data: ")[1]);
        streamData_ += chunk.choices[0].delta.content;
        if (chunk.choices[0].delta.content !== undefined) {
          setStreamData(streamData_);
        } else {
          setPromptData("");
        }
      }
    } catch (error) {
      console.error("Error making API request", error);
    }
  };

  useEffect(() => {
    const o = setInterval(() => {
      setChangeImage(Math.random().toString());
    }, 20000);

    return () => clearInterval(o);
  }, []);

  // a function to copy a given string to users clipboard
  const copyToClipboard = (str: string) => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };



  return (
    <div style={{ textAlign: "center", padding: 7, backgroundColor: "green" }}>
      <Toast ref={toastRef} />
      <div style={{ width: "100%", height: "100%" }}>
        <ScrollPanel
          className="shads"
          style={{
            width: "100%",
            borderRadius: 5,
            textAlign: "left",
            padding: 15,
            height: 475,
            backgroundColor: "#91BF22",

            color: "black",
          }}
        >
          <div onClick={()=>{
            setChangeImage(Math.random().toString());
          }} style={{ float: "left", marginRight: 15, marginBottom: 5 }}>
            <AdminMonkey _imageChange={_changeImage} />
          </div>
          <div style={{ marginTop: -10 }}>
            <div style={{minHeight: 100}}>
            <Markdown children={streamData} />
            </div>
            
            <br />
            <hr />
            <div
              style={{ backgroundColor: "green", padding: 5, borderRadius: 5 }}
            >
              <Button
                label="How many sign ups?"
                icon="pi pi-question-circle"
                iconPos="right"
                style={{ width: "100%", color: "white", marginBottom: 5 }}
                outlined
                onClick={async (e) => {
                  handleQuickButtons(
                    "# How many sign ups do we have for the Codeplant AI Club?"
                  );
                }}
              ></Button>
              <Button
                style={{ width: "100%", color: "white", marginBottom: 5 }}
                outlined
                label="What website messages do we have?"
                icon="pi pi-microchip-ai"
                iconPos="right"
                onClick={async (e) => {
                  handleQuickButtons(
                    "# What website messages do we have for the Codeplant AI Club?"
                  );
                }}
              />
         
            </div>
          </div>
        </ScrollPanel>
        <br />
        <div style={{ display: "flex" }}>
          <InputTextarea
            value={promptData}
            onChange={(e) => {
              setPromptData(e.currentTarget.value);
              setChangeImage(Math.random().toString());
            }}
            rows={3}
            cols={30}
            placeholder="Type your message here..."
            style={{ width: "80%", marginBottom: "10px" }}
          />

          <Button
            icon="pi pi-send"
            size="large"
            style={{
              width: "20%",
              marginBottom: "10px",
              color: "black",
              backgroundColor: "#91BF22",
            }}
            onClick={handleSubmit}
          />
        </div>
            <br />
            
        {/* chat history */}
        {_messages.length > 0 && (
          <Accordion>
            <AccordionTab header="History">
              <Button
                icon="pi pi-copy"
                label="Copy to Clipboard"
                onClick={() => {
                  copyToClipboard(
                    _messages
                      .map((message_: any, index: number) => {
                        return message_.content;
                      })
                      .join("\n")
                  );
                  toastRef.current?.show({
                    severity: "success",
                    summary: "Copied to clipboard",
                    detail: "Chat history copied to clipboard",
                    life: 3000,
                  });
                }}
              ></Button>
              {_messages.map((message_: any, index: number) => {
                if (message_.role !== "system" || message_.role !== "user") {
                  return (
                    <div>
                      <Markdown children={message_.content} />
                    </div>
                  );
                }
              })}
            </AccordionTab>
          </Accordion>
        )}
      </div>
    </div>
  );
};
