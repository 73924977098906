import React from 'react';

const Timeline: React.FC = () => {
  return (
    <div className="container">
      <ul className="timeline">
        <li>
          <div className="timeline-panel bg-green">
            <div className="timeline-heading">
              <h4 className="timeline-title">⏀ George Boole (1815-1864)</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/George_Boole" target="_blank" rel="noopener noreferrer">
                <img src="/boole.jpg" alt="George Boole" className="timeline-image" />
              </a>
              <p>Boolean Algebra (1847)</p>
              <p>George Boole created Boolean algebra, which is essential for making computer programs work. It's like the rules computers follow to understand yes/no or true/false decisions.</p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-panel bg-blue">
            <div className="timeline-heading">
              <h4 className="timeline-title">🤖 Alan Turing (1912-1954)</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/Alan_Turing" target="_blank" rel="noopener noreferrer">
                <img src="/turing.jpeg" alt="Alan Turing" className="timeline-image" />
              </a>
              <p>Turing Machine (1936), Turing Test (1950)</p>
              <p>Alan Turing thought of a machine that could solve any problem a computer can if it had the right instructions. He also came up with a test to see if a computer could pretend to be human.</p>
              <ul>
                <li><strong>Language Skills:</strong> The machine should understand and respond naturally and appropriately.</li>
                <li><strong>Thinking and Solving:</strong> The machine should solve problems and think logically like a human.</li>
                <li><strong>Creativity:</strong> The machine should create new and original ideas.</li>
                <li><strong>Emotions:</strong> The machine should recognize and respond to emotions.</li>
                <li><strong>Learning:</strong> The machine should know a lot and learn from new experiences.</li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div className="timeline-panel bg-purple">
            <div className="timeline-heading">
              <h4 className="timeline-title">☎️ Claude Shannon (1916-2001)</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/Claude_Shannon" target="_blank" rel="noopener noreferrer">
                <img src="/shannon.png" alt="Claude Shannon" className="timeline-image" />
              </a>
              <p>Information Theory (1948)</p>
              <p>Claude Shannon's work made it possible for us to send and store data efficiently. While working for Bell Labs on their telephone network, he designed and implemented a complete digital encoding technique.
                  Think about texting or watching videos online - that's thanks to his ideas!</p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-panel bg-red">
            <div className="timeline-heading">
              <h4 className="timeline-title">💻 John Von Neumann (1903-1957)</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/John_von_Neumann" target="_blank" rel="noopener noreferrer">
                <img src="/neumann.png" alt="John Von Neumann" className="timeline-image" />
              </a>
              <p>Von Neumann Architecture (1945)</p>
              <p>John Von Neumann designed a way to build computers so they could do multiple tasks easily. He based his designs on his observation of how the human brain / body work. This architecture is still used in computers today!</p>
            </div>
          </div>
        </li>
        <li>
          <div className="timeline-panel bg-orange">
            <div className="timeline-heading">
              <h4 className="timeline-title">⏰ John McCarthy (1927-2011)</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/John_McCarthy_(computer_scientist)" target="_blank" rel="noopener noreferrer">
                <img src="/mccarthy.avif" alt="John McCarthy" className="timeline-image" />
              </a>
              <p>John McCarthy is the person who came up with the term "Artificial Intelligence" (in 1956) and invented a programming language (lisp) that helped researchers develop AI.</p>
              <p>John also initiated the development of time-sharing systems to fairly share expensive computing resources. These practices are now the foundations of the cloud computing industry. </p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-panel bg-teal">
            <div className="timeline-heading">
              <h4 className="timeline-title">📷 Yann LeCun (1960-)</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/Yann_LeCun" target="_blank" rel="noopener noreferrer">
                <img src="/jan.webp" alt="Yann LeCun" className="timeline-image" />
              </a>
              <p>Yann LeCun is the Head of AI Research at Meta, his work on Convolutional Neural Networks (CNNs) (1988) helps computers recognize images, like how your phone can identify your face or a car can drive by itself.</p>
            </div>
          </div>
        </li>
        <li>
          <div className="timeline-panel bg-yellow">
            <div className="timeline-heading">
              <h4 className="timeline-title">🧨 Ilya Sutskever (1985-)</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/Ilya_Sutskever" target="_blank" rel="noopener noreferrer">
                <img src="/ilya.jpg" alt="Ilya Sutskever" className="timeline-image" />
              </a>
              <p>Ilya Sutskever co-founded OpenAI and helped create models (Transformers 2017) that make it possible for AI to understand and generate human language better than ever before.</p>
            </div>
          </div>
        </li>
        {/* Major AI Models and Date Ranges */}
        <li className="timeline-inverted">
          <div className="timeline-panel bg-green">
            <div className="timeline-heading">
              <h4 className="timeline-title">1950s - The Birth of AI</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/Dartmouth_workshop" target="_blank" rel="noopener noreferrer">
                <img src="/1950s.GIF" alt="1950s" className="timeline-image" />
              </a>
              <p>1956: Dartmouth Conference, birth of AI as a field</p>
              <p>The Dartmouth Conference in 1956 is when scientists officially started the field of AI, where they began exploring how to make machines intelligent.</p>
            </div>
          </div>
        </li>
        <li>
          <div className="timeline-panel bg-blue">
            <div className="timeline-heading">
              <h4 className="timeline-title">1960s - Industry Adoption</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/Unimate" target="_blank" rel="noopener noreferrer">
                <img src="/robotfail.GIF" alt="1960s" className="timeline-image" />
              </a>
              <p>The 1960s saw the creation of the first industrial robot, Unimate (1961), and ELIZA, the first chatbot (1966) that could carry on a simple conversation with humans.</p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-panel bg-purple">
            <div className="timeline-heading">
              <h4 className="timeline-title">1970s - New Applications</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/Mycin" target="_blank" rel="noopener noreferrer">
                <img src="bacteria.gif" width="150" height="100" alt="1970s" className="timeline-image" />
              </a>
              <p>MYCIN (1972) was an early AI that helped doctors diagnose infections and recommend treatments, showcasing how AI can assist in medicine.</p>
            </div>
          </div>
        </li>
        <li>
          <div className="timeline-panel bg-red">
            <div className="timeline-heading">
              <h4 className="timeline-title">1980s - Computers Get More Human</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/NETtalk" target="_blank" rel="noopener noreferrer">
                <img src="/vic20.avif" alt="1980s" className="timeline-image" />
              </a>
              <p>The 1980s introduced XCON (1980), which helped set up computer systems, and NETtalk (1986), an early AI that could learn to read text aloud.</p>
              <p>The 1980s also saw the birth of personal computers, making technology more accessible to people of all background and ages, like Yann and Ilya. 😃</p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-panel bg-orange">
            <div className="timeline-heading">
              <h4 className="timeline-title">1990s - AI Begins to Compete</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/Deep_Blue_(chess_computer)" target="_blank" rel="noopener noreferrer">
                <img src="/ai_chess.GIF" width="150" height="100" alt="1990s" className="timeline-image" />
              </a>
              <p>1997: Deep Blue, chess-playing computer defeats world champion</p>
              <p>In 1997, IBM's Deep Blue defeated world chess champion Garry Kasparov, showing that AI could compete with and beat humans in complex games.</p>
              <p>The term 'Computerphobia' is coined to describe the rising fear of computers.</p>
            </div>
          </div>
        </li>
        <li>
          <div className="timeline-panel bg-teal">
            <div className="timeline-heading">
              <h4 className="timeline-title">2000s - Computers Begin to Predict</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/Deep_learning#History" target="_blank" rel="noopener noreferrer">
                <img src="/cnn.gif" alt="2000s" className="timeline-image" />
              </a>
              <p>2006: Geoffrey Hinton's Deep Belief Networks</p>
              <p>Geoffrey Hinton's work in 2006 on Deep Belief Networks allowed AI to understand patterns and make predictions, paving the way for more advanced AI technologies.</p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-panel bg-yellow">
            <div className="timeline-heading">
              <h4 className="timeline-title">2010s - Real-Time Computer Vision</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/AlexNet" target="_blank" rel="noopener noreferrer">
                <img src="/alexnet.gif" alt="2010s" className="timeline-image" />
              </a>
              <p>2012: AlexNet, CNN that won ImageNet competition</p>
              <p>2014: GANs (Generative Adversarial Networks)</p>
              <p>2015: AlphaGo beats professional Go player</p>
              <p>2017: Transformer, Attention Is All You Need</p>
              <p>The 2010s saw major breakthroughs with AlexNet winning the ImageNet competition, GANs creating realistic images, AlphaGo beating a Go champion, and the Transformer model improving language processing.</p>
            </div>
          </div>
        </li>
        <li>
          <div className="timeline-panel bg-green">
            <div className="timeline-heading">
              <h4 className="timeline-title">2020s - AI Creates and Converses</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/GPT-3" target="_blank" rel="noopener noreferrer">
                <img src="/gpt.gif" alt="2020s" className="timeline-image" />
              </a>
              <p>2020: GPT-3, large-scale language model by OpenAI - 7 grade IQ</p>
              <p>2021: AlphaFold, protein folding AI by Google DeepMind</p>
              <p>2022: DALL-E, image generation model by OpenAI</p>
              <p>2023: ChatGPT, conversational AI by OpenAI - high school IQ</p>
              <p>In the 2020s, AI has reached new heights with GPT-3 writing human-like text, AlphaFold solving advanced protein structures, DALL-E creating images from text, and ChatGPT having conversations like a human.</p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-panel bg-purple">
            <div className="timeline-heading">
              <h4 className="timeline-title">2024 - The Future</h4>
            </div>
            <div className="timeline-body">
              <a href="https://en.wikipedia.org/wiki/Future" target="_blank" rel="noopener noreferrer">
                <img src="/future.gif" alt="2024" className="timeline-image" />
              </a>
              <p>GPT-5 is expected to have the IQ of a Phd student.</p>
              <p>Where do we go from here?</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Timeline;
