import React, { useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import imagesData from './content/code-monkey.json';
import AiService from './services/ai.service';

interface MonkeyProps {
    _imageChange: string
}
const Monkey = (props: MonkeyProps) => {
    const [currentImage, setCurrentImage] = useState<string>(imagesData.images[Math.random() * imagesData.images.length | 0]);

    useEffect(() => {
       setCurrentImage(imagesData.images[Math.random() * imagesData.images.length | 0]);
    }, [props._imageChange]);

    
    return (
  
            <Image src={`/code-monkey/resized/${currentImage}`} alt="Monkey Image" width="100" imageStyle={{borderRadius: '15px', boxShadow: '1px 1px 1px 1px rgba(20,20,20,0.6)'}}/>

    );
};

export default Monkey;
