import { createContext, ReactNode, useContext, useState } from 'react';



export interface Config {
    role: string;
    name: string;
    // Add other config properties as needed
  }
  
  interface ConfigProviderProps {
    config: Config;
    children: ReactNode;
  }


  const ConfigContext = createContext<Config | undefined>(undefined);

  export const ConfigProvider: React.FC<ConfigProviderProps> = ({ config, children }) => {
    return (
      <ConfigContext.Provider value={config}>
        {children}
      </ConfigContext.Provider>
    );
  };

  // Create a custom hook to use the Config context
export const useConfig = () => {
    const context = useContext(ConfigContext);
    if (!context) {
      throw new Error('useConfig must be used within a ConfigProvider');
    }
    return context;
  };
  