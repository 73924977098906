import logo from './images/codeplant_images/logo_med.png'
import SideScrollerGame from './side-scroller';

type Props = {}
const Footer = (props: Props) => {
  return (
    <>
    <div className='footerPanel'>
        <div style={{width: '35%', padding: '25px'}}>
            <img src={logo} width="400" />
            <p>© 2024 Codeplant | All Rights Reserved</p>
        </div>
        <div style={{width: '30%', textAlign: 'left'}}>
            <h3>Contact Us</h3>
            <p><strong>Codeplant</strong></p>
            <p>+27 (72) 422 8644</p>
            <p><a href="mailto:support@codeplant.co.za">support@codeplant.co.za</a></p>
            <br />
            
        </div>
        <div style={{width: '20%', textAlign: 'left'}}>
            <div style={{display: 'flex'}}>
            <h3>Follow Us</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p><a target='_blank'  href="https://youtube.com/codeplantsa"><i className='pi pi-youtube'></i></a></p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p><a target='_blank'  href="https://github.com/orgs/codeplant-sa"><i className='pi pi-github'></i></a></p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p><a target='_blank'  href="https://www.instagram.com/codeplantsa"><i className='pi pi-instagram'></i></a></p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p><a target='_blank'  href="https://www.facebook.com/codeplantsa"><i className='pi pi-facebook'></i></a></p>
               </div>
            <SideScrollerGame />
        </div>
    </div>
    <div className='footerMobile'>
        <div style={{width: '100%', padding: '25px', textAlign: 'center'}}>
            <p>© 2024 Codeplant | All Rights Reserved</p>
        </div>
        <div style={{width: '100%', textAlign: 'center'}}>
            <h3>Contact Us</h3>
            <p><strong>Codeplant</strong></p>
            <p>+27 (72) 422 8644</p>
            <p><a href="mailto:support@codeplant.co.za">support@codeplant.co.za</a></p>
        </div>
        <div style={{width: '100%', textAlign: 'center'}}>
            <h3>Follow Us</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p><a target='_blank'  href="https://www.youtube.com/watch?v=b5e20bz-nV4&list=PLf0CC8kpgVTIEAj7tv_hRw5wILa9xRyeE"><i className='pi pi-youtube'></i></a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a target='_blank'  href="https://github.com/orgs/codeplant-sa"><i className='pi pi-github'></i></a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a target='_blank'  href="https://www.instagram.com/codeplantsa"><i className='pi pi-instagram'></i></a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a target='_blank'  href="https://www.facebook.com/codeplantsa"><i className='pi pi-facebook'></i></a></p>

        </div>
    </div>
    </>
  )
}

export default Footer