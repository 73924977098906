import "primereact/resources/themes/lara-light-cyan/theme.css";
import HomePage from './components/home';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createContext, ReactNode, useContext, useState } from 'react';
import AdminDashboard from "./components/admin/dashboard";
import AdminService from "./components/services/admin.service";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ConfigProvider, Config } from "./components/services/config.provider";
import { Card } from "primereact/card";
import { Image } from "primereact/image";

function AdminPage() {
  return <AdminDashboard />;
}


function AdminRoute({ children }: { children: JSX.Element }) {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [config, setConfig] = useState<Config>();


  const authenticate = async () => {

    const _result:any = await AdminService.authenticate(password);
    if(!_result) {
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
      setConfig(JSON.parse(_result))
    }

  }

  return isAuthenticated && config ? 
  <ConfigProvider config={config}>{children}</ConfigProvider> : 
    <Card style={{width: '100%', margin: 'auto', padding: 10, textAlign: 'center'}}>
      <div style={{display: 'flex', width: '100%', margin: 'auto', padding: '25px'}}>
    <InputText type="password"  style={{width: '80%'}} placeholder="Who goes there?" value={password} onChange={(e)=>{ setPassword(e.target.value)}}/>
    <Button  size="small" style={{backgroundColor: 'white'}} onClick={authenticate}><img src="/logo_tiny.png" /></Button>
    </div>
    </Card>
}

function App() {

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <AdminPage />
              </AdminRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
