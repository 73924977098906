import { Badge } from 'primereact/badge';
import { Panel } from 'primereact/panel';
import React, { useEffect, useRef, useState } from 'react';

const SideScrollerGame: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [playerPosition, setPlayerPosition] = useState({ x: 50, y: 100 });
  const [obstacles, setObstacles] = useState<Array<{ x: number, y: number }>>([]);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState<number>(() => {
    return parseInt(localStorage.getItem('highScore') || '0', 10);
  });

  const handleKeyDown = (event: KeyboardEvent) => {
    setPlayerPosition((prevPosition) => {
      const newPosition = { ...prevPosition };
      const moveAmount = 5;

      switch (event.key) {
        case 'w':
          newPosition.y = Math.max(prevPosition.y - moveAmount, 0);
          break;
        case 's':
          newPosition.y = Math.min(prevPosition.y + moveAmount, 180); // 200 (canvas height) - 20 (player height)
          break;
        case 'a':
          newPosition.x = Math.max(prevPosition.x - moveAmount, 0);
          break;
        case 'd':
          newPosition.x = Math.min(prevPosition.x + moveAmount, 360); // 400 (canvas width) - 20 (player width)
          break;
        default:
          break;
      }
      updateObstacles();
      return newPosition;
    });

    setScore((prevScore) => prevScore + (.5 * playerPosition.x)); // Increment score for each valid move
  };

  const spawnObstacle = () => {
    setObstacles((prevObstacles) => [
      ...prevObstacles,
      { x: 400, y: Math.floor(Math.random() * 180) } // Spawn new obstacle at random y position
    ]);
  };

  const updateObstacles = () => {
    setObstacles((prevObstacles) =>
      prevObstacles
        .map((obstacle) => ({ ...obstacle, x: obstacle.x - 5 })) // Move obstacle to the left
        .filter((obstacle) => obstacle.x > 0) // Remove obstacles that have moved off the screen
    );
  };

  const checkCollision = () => {
    for (let obstacle of obstacles) {
      if (
        playerPosition.x < obstacle.x + 20 &&
        playerPosition.x + 20 > obstacle.x &&
        playerPosition.y < obstacle.y + 20 &&
        playerPosition.y + 20 > obstacle.y
      ) {
        if (score > highScore) {
          localStorage.setItem('highScore', score.toString());
          setHighScore(score); // Update high score if the current score is higher
        }
        setPlayerPosition({ x: 50, y: 100 });
        setObstacles([]);
        setScore(0); // Reset score on collision
        break;
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateObstacles();
      checkCollision();
      if (Math.random() < 0.03) spawnObstacle(); // Spawn a new obstacle randomly
    }, 100);

    return () => clearInterval(interval);
  }, [playerPosition, obstacles]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => handleKeyDown(event);
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw player
        ctx.fillStyle = 'blue';
        ctx.fillRect(playerPosition.x, playerPosition.y, 20, 20);

        // Draw obstacles
        ctx.fillStyle = 'red';
        for (let obstacle of obstacles) {
          ctx.fillRect(obstacle.x, obstacle.y, 20, 20);
        }
      }
    }
  }, [playerPosition, obstacles]);

  return (
    <div style={{marginTop: '1125px'}}>
      <h4>🎖️ Your reward for scrolling down this far 🎖️</h4>
      <p>This entire side scrolling game was created by Ai using 1 prompt.</p>
    <Badge value={'Use (w,a,s,d) to avoid the red pixels'}/>
      <hr />
      
      <canvas ref={canvasRef} width={400} height={200} style={{ border: '1px solid black' }}></canvas>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <p>Score: {score}</p>
      <p style={{ color: 'red' }}>High Score: {highScore}</p>
        </div>
    </div>
  );
};

export default SideScrollerGame;
