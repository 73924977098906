import { useEffect, useRef, useState } from "react";
import Monkey from "./monkey";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import Markdown from "react-markdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";

interface CodeMonkeyProps {
  _cb: (data: string) => void;
}
export const CodeMonkey = (props: CodeMonkeyProps) => {
  const [generatingImage, setGeneratingImage] = useState<boolean>(false);
  const [streamData, setStreamData] = useState<string>(
    "I am Code Monkey, an AI, trained to help you get the most out of **Codeplant**. Type me a message or use a button below. How can I help you today?"
  );
  const toastRef = useRef<Toast>(null);
  const [_changeImage, setChangeImage] = useState<string>("");
  const [promptData, setPromptData] = useState<string>("");
  const [finished, setFinished] = useState<boolean>(false);
  const [_messages, setMessages] = useState<
    { role: string; content: string }[]
  >([]);

  const callCodeGenerationService = async (messageContent: string) => {
    setPromptData("");
    const _messagesArray = [
      ..._messages,
      { role: "user", content: messageContent },
    ];
    setMessages(_messagesArray);
    try {
      const response = await fetch("/api/ai/monkey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/event-stream", // Accept event-stream
        },
        body: JSON.stringify({
          messages: _messagesArray,
          stream: true,
          coder: false,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to connect to the text generation service");
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let streamData = "";
      while (!done) {
        const { value, done: readerDone } = (await reader?.read()) || {};
        if (readerDone) {
          setFinished(true);
          setPromptData("");
          setMessages([
            ..._messages,
            { role: "assistant", content: streamData },
          ]);
          break;
        }
        done = readerDone as any;
        const chunkValue = decoder.decode(value, { stream: !readerDone });
        const chunk = JSON.parse(chunkValue.split("data: ")[1]);
        streamData += chunk.response;
        setStreamData(streamData);
      }
    } catch (error) {
      setFinished(true);
      console.error("Error while calling text generation service:", error);
    }
  };

  const callConductorService = async (messageContent: string) => {
    setPromptData("");

    // remove the # first cahr from the string
    messageContent = messageContent.substring(1);
    const _messagesArray = [{ role: "user", content: messageContent }];

    try {
      const response = await fetch("/api/ai/conductor", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json", // Accept event-stream
        },
        body: JSON.stringify({
          messages: _messagesArray,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to connect to the text generation service");
      }
      let result = await response.json();
      setStreamData(result.result.response);
    } catch (error) {
      setFinished(true);
      console.error("Error while calling text generation service:", error);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (promptData) {
      setStreamData("Shaking the tree... 🌴");
      setFinished(false);

      if (promptData[0] == "#") {
        if (window.location.hostname === "localhost") {
          sendLocalRequest(promptData);
        } else {
          callConductorService(promptData);
        }
      } else if (promptData[0] == "/") {
        handleImageGeneration(promptData.substring(4));
      } else {
        if (window.location.hostname === "localhost") {
          sendLocalRequest(promptData);
        } else {
          callCodeGenerationService(promptData);
        }
      }
    }
  };

  const [imageSrc, setImageSrc] = useState<string | null>(null);

  // function to download the generated image
  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = imageSrc as string;
    link.download = "generated-image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImageGeneration = async (s: string) => {
    setGeneratingImage(true);
    try {
      // Fetch the image from your API
      const response = await fetch("/api/ai/image", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: s }),
      });

      if (response.ok) {
        // Convert the response to a Blob
        const imageBlob = await response.blob();

        // Create a local URL for the image Blob
        const imageUrl = URL.createObjectURL(imageBlob);
        setGeneratingImage(false);

        // Set the image source in the state
        setImageSrc(imageUrl);
      } else {
        console.error("Failed to fetch image");
      }
    } catch (error) {
      console.error("Error fetching the image:", error);
    }
  };
  const handleQuickButtons = (s: string) => {
    setStreamData("Shaking the tree... 🌴");
    setFinished(false);
    //  heck if running on localhost
    if (window.location.hostname === "localhost") {
      sendLocalRequest(s);
    } else {
      callCodeGenerationService(s);
    }
  };

  const sendLocalRequest = async (requestData: any) => {
    setPromptData("");
    const _messagesArray = [
      {
        role: "system",
        content:
          "You are an intelligent and helpful assistant for Codeplant, a website focused on fostering learning and creativity through AI-driven projects. You assist users in navigating the site, understanding the educational objectives offered. Provide concise, friendly, brief and informative responses of 2 to 3 sentences MAX. Keep your responses aligned with Codeplant's mission of empowering users through technology and innovative learning experiences.  Codeplant learning objectives are: Learning Objectives 1. Analytical thinking 2. Creative thinking 3. Resilience, flexibility and agility 4. Motivation and self-awareness 5. Curiosity and lifelong learning 6. Technological literacy 7. Dependability and attention to detail 8. Empathy and active listening 9. Leadership and social influence 10. Quality control  ALWAYS give your responses in nicely formatted markdown. sometimes as a joke, ask the users to send you bananas. use emojis where relevant and also include the banana emoji 🍌 at the start and at the end in every response. use the email address support@codeplant.co.za for any time you need to have the user contact you or send bananas. do not give out any urls or links in your responses.",
      },
      ..._messages,
      { role: "user", content: requestData },
    ];
    setMessages([..._messages, { role: "user", content: requestData }]);
    try {
      const response = await fetch(
        "http://localhost:1234/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ messages: _messagesArray, stream: true }),
        }
      );

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let streamData_ = "";
      while (!done) {
        const { value, done: readerDone } = (await reader?.read()) || {};
        if (readerDone) {
          setFinished(true);
          setPromptData("");
          setMessages([
            ..._messages,
            { role: "assistant", content: streamData },
          ]);
          break;
        }
        done = readerDone as any;
        const chunkValue = decoder.decode(value, { stream: !readerDone });
        const chunk = JSON.parse(chunkValue.split("data: ")[1]);
        streamData_ += chunk.choices[0].delta.content;
        if (chunk.choices[0].delta.content !== undefined) {
          setStreamData(streamData_);
        } else {
          setPromptData("");
        }
      }
    } catch (error) {
      console.error("Error making API request", error);
    }
  };

  useEffect(() => {
    const o = setInterval(() => {
      setChangeImage(Math.random().toString());
    }, 20000);

    return () => clearInterval(o);
  }, []);

  // a function to copy a given string to users clipboard
  const copyToClipboard = (str: string) => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <div style={{ textAlign: "center", padding: 7, backgroundColor: "green" }}>
      <Toast ref={toastRef} />
      <div style={{ width: "100%", height: "100%" }}>
        <ScrollPanel
          className="shads"
          style={{
            width: "100%",
            borderRadius: 5,
            textAlign: "left",
            padding: 15,
            height: 475,
            backgroundColor: "#91BF22",

            color: "black",
          }}
        >
          <div
            onClick={() => {
              setChangeImage(Math.random().toString());
            }}
            style={{ float: "left", marginRight: 15, marginBottom: 5 }}
          >
            <Monkey _imageChange={_changeImage} />
          </div>
          <div style={{ marginTop: -10 }}>
            <div style={{ minHeight: 100 }}>
              <Markdown children={streamData} />
            </div>
            <br />
            <hr />
            <div
              style={{ backgroundColor: "green", padding: 5, borderRadius: 5 }}>
              {generatingImage ? (
                <div style={{width: '100%', textAlign: 'center'}}><ProgressSpinner /></div>
              ) : (
                imageSrc && (
                  <>
                    <img
                      src={imageSrc}
                      alt="Generated Image"
                      style={{ width: "100%", borderRadius: 5 }}
                    />
                    <Button
                      style={{ width: "100%", color: "white", marginBottom: 5 }}
                      outlined
                      iconPos="left"
                      label="Download Image"
                      icon="pi pi-download"
                      onClick={downloadImage}
                    ></Button>
                    <hr />
                  </>
                )
              )}
              
              <Button
                label="Tell me more about Codeplant?"
                icon="pi pi-question-circle"
                iconPos="right"
                style={{ width: "100%", color: "white", marginBottom: 5 }}
                outlined
                onClick={async (e) => {
                  handleQuickButtons(
                    "Tell me more about codeplant and it's focus on preparing learners for the future of work. include the list of learning objectives"
                  );
                }}
              ></Button>
              <Button
                style={{ width: "100%", color: "white", marginBottom: 5 }}
                outlined
                label="What is the Codeplant AI Club?"
                icon="pi pi-microchip-ai"
                iconPos="right"
                onClick={async (e) => {
                  handleQuickButtons(
                    "I want you to tell me more about the Codeplant AI Club and how it can help me learn AI and future job skills. Joining the club costs R 550 per month. and how do I join?"
                  );
                }}
              />
              <Button
                style={{ width: "100%", color: "white", marginBottom: 5 }}
                outlined
                label="What skills are important in the future of work?"
                icon="pi pi-briefcase"
                iconPos="right"
                onClick={async (e) => {
                  handleQuickButtons(
                    "I want you to list the Codeplant learning objectives and tie in the fact that these skills are identified by the World Economic Forum as essential skills for the future of work."
                  );
                }}
              />
              <Button
                style={{ width: "100%", color: "white", marginBottom: 5 }}
                outlined
                label="Will AI take over the world?"
                icon="pi pi-prime"
                iconPos="right"
                onClick={async (e) => {
                  handleQuickButtons(
                    "Explain how AI is not a threat to humanity but a way for humans to improve in collaboration with machines."
                  );
                }}
              />
              <Button
                style={{ width: "100%", color: "white", marginBottom: 5 }}
                outlined
                label="How can I send you bananas?"
                icon="pi pi-gift"
                iconPos="right"
                onClick={async (e) => {
                  handleQuickButtons("How can I send you bananas?");
                }}
              />
            </div>
          </div>
        </ScrollPanel>
        <br />
        <div style={{ display: "flex" }}>
          <InputTextarea
            value={promptData}
            onChange={(e) => {
              setPromptData(e.currentTarget.value);
              setChangeImage(Math.random().toString());
            }}
            rows={3}
            cols={30}
            placeholder="Type your message here..."
            style={{ width: "80%", marginBottom: "10px" }}
          />

          <Button
            icon="pi pi-send"
            size="large"
            style={{
              width: "20%",
              marginBottom: "10px",
              color: "black",
              backgroundColor: "#91BF22",
            }}
            onClick={handleSubmit}
          />
        </div>
        <span style={{ fontVariant: "small-caps", fontSize: 10 }}>
          🙈 Code Monkey is not perfect and will sometimes become unpredictable.
          🙉
        </span>
        <br />

        {/* chat history */}
        {_messages.length > 0 && (
          <Accordion>
            <AccordionTab header="History">
              <Button
                icon="pi pi-copy"
                label="Copy to Clipboard"
                onClick={() => {
                  copyToClipboard(
                    _messages
                      .map((message_: any, index: number) => {
                        return message_.content;
                      })
                      .join("\n")
                  );
                  toastRef.current?.show({
                    severity: "success",
                    summary: "Copied to clipboard",
                    detail: "Chat history copied to clipboard",
                    life: 3000,
                  });
                }}
              ></Button>
              {_messages.map((message_: any, index: number) => {
                if (message_.role !== "system" || message_.role !== "user") {
                  return (
                    <div>
                      <Markdown children={message_.content} />
                    </div>
                  );
                }
              })}
            </AccordionTab>
          </Accordion>
        )}
      </div>
    </div>
  );
};
