interface SignUpForm {
    name: string;
    email: string;
    cell: string;
    childName: string;
    childAge: string;
}

export class SignUpService {
 

    constructor() {

    }

    async submitSignup(signupForm: SignUpForm): Promise<Response> {
        try {
            const response = await fetch(`/api/signup/submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(signupForm),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            return response;
        } catch (error) {
            console.error('Error submitting signup:', error);
            throw error;
        }
    }
}
