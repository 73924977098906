
import SignUp from './sign-up';

type Props = {
    _signUp: Function
}

const AiClub = (props: Props) => {
  return (
    <div>
        <h1>AI Club</h1>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <img src="1.JPG" alt="AI Club 1" width="300" style={{margin: 10, borderRadius: 10}} />
          <img src="2.JPG" alt="AI Club 2"  width="300" style={{margin: 10, borderRadius: 10}} />
          <img src="3.JPG" alt="AI Club 3" width="300"  style={{margin: 10, borderRadius: 10}} />
          <img src="4.JPG" alt="AI Club 4"  width="300" style={{margin: 10, borderRadius: 10}} />
        </div>
        <div style={{width: '80%', margin: 'auto', textAlign: 'center'}}>
          <p>In June of 2024, Codeplant was invited to speak on the topic of Artificial Intelligence at Generation School in Hermanus. During this event, it was discussed that a club centered around learning about and using AI for positive change, would be a great idea. The AI Club is now active and taking sign ups for it's pilot during the 4th term of the school year. There are many ways to get involved with the club from becoming a full member, to attending one of the public events.</p>
          <SignUp onSignUp={()=>{

          }} />

          

        </div>
    </div>
  )
}

export default AiClub