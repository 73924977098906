import React, { useEffect } from 'react'
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';


type Props = {
    _cb: (e: any) => void
}

const JoinAIClubPromo = (props: Props) => {

    const overlayRef = React.useRef<OverlayPanel>(null);
    const _imgs: string[] = [
        // "make-ai/make-an-ai-3.png",
        "make-ai/make-an-ai-4.png",
        "make-ai/make-an-ai-5.png"
    ];
    
    const [_img, setImg] = React.useState<string>(_imgs[0]);
    
    useEffect(() => {
        // set an interval that changes the image every 5 seconds
        let i = 0;
        const interval = setInterval(() => {
            setImg(_imgs[i]);
            i = (i + 1) % _imgs.length;
        }, 1500);

        return () => clearInterval(interval);

    }, []);
    


  return (
    <div style={{width: '100%', padding: 7, textAlign: 'center', display: 'flex', border: '1px solid black', borderRadius: 10}}>
       
        <img src={_img} style={{margin: 'auto', width: '50%', borderRadius: 10}}/>
        <div style={{color: 'white', padding: 10, textAlign: 'justify',margin: 2, borderRadius: 10}}>
            <h3 style={{textAlign: 'center', color: 'black'}}>Create Your Own AI</h3>
        <p>Join the AI Club and Create your own AI bot with a custom personality that you design! Learn how we created Code Monkey and how you can make your own personal digital assistant.</p>
        <Button label="Join the AI Club" onClick={props._cb} icon="pi pi-microchip-ai" outlined style={{color: 'black', width: '100%', backgroundColor: '#91BF22'}}/>
        </div>
    </div>
  )
}

export default JoinAIClubPromo