import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';

interface FormData {
  learningStyle: string;
  subjectInterests: string[];
  hobbies: string;
  collaborationPreference: string;
  goals: string[];
  priorExperience: string;
  problemSolvingApproach: string;
  aiTopicsInterest: string[];
  otherSubjectInterest?: string;
  otherGoal?: string;
  otherAiTopicInterest?: string;
}

interface DiagnosticFormProps {
  onSubmit: (formData: FormData) => void;
}

const DiagnosticForm: React.FC<DiagnosticFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<FormData>({
    learningStyle: '',
    subjectInterests: [],
    hobbies: '',
    collaborationPreference: '',
    goals: [],
    priorExperience: '',
    problemSolvingApproach: '',
    aiTopicsInterest: [],
  });

  const handleChange = (
    e: any,
    fieldName: keyof FormData,
    isCheckbox?: boolean
  ) => {
    const value = e.value;
    if (isCheckbox) {
      setFormData((prevState) => {
        const list = prevState[fieldName] as string[];
        if (e.checked) {
          return { ...prevState, [fieldName]: [...list, value] };
        } else {
          return {
            ...prevState,
            [fieldName]: list.filter((item) => item !== value),
          };
        }
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const handleOtherChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof FormData
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Call the callback function passed via props
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-grid">
        {/* Left Column */}
        <div className="p-col-12 p-md-6">
          {/* Question 1 */}
          <div className="field">
            <p>1. Which of the following activities do you enjoy the most when learning new things?</p>
            {[
              'Watching videos or visual presentations.',
              'Listening to explanations or discussions.',
              'Doing hands-on experiments or projects.',
              'Reading articles or writing summaries.',
            ].map((option, index) => (
              <div key={index} className="field-radiobutton">
                <RadioButton
                  inputId={`learningStyle${index}`}
                  name="learningStyle"
                  value={option}
                  onChange={(e) => handleChange(e, 'learningStyle')}
                  checked={formData.learningStyle === option}
                />
                <label htmlFor={`learningStyle${index}`}>{option}</label>
              </div>
            ))}
          </div>

          {/* Question 3 */}
          <div className="field">
            <p>3. What hobbies or extracurricular activities do you participate in?</p>
            <InputTextarea
              name="hobbies"
              value={formData.hobbies}
              onChange={(e) => handleChange(e, 'hobbies')}
              rows={3}
              cols={30}
            />
          </div>

          {/* Question 5 */}
          <div className="field">
            <p>5. What are your goals for joining this AI club? (Select all that apply)</p>
            {[
              'Learn the basics of AI and machine learning',
              'Build my own AI projects',
              'Prepare for a future career in technology',
              'Meet other students interested in AI',
            ].map((goal, index) => (
              <div key={index} className="field-checkbox">
                <Checkbox
                  inputId={`goals${index}`}
                  name="goals"
                  value={goal}
                  onChange={(e) => handleChange(e, 'goals', true)}
                  checked={formData.goals.includes(goal)}
                />
                <label htmlFor={`goals${index}`}>{goal}</label>
              </div>
            ))}
            <div className="field-checkbox">
              <Checkbox
                inputId="goalsOther"
                name="goals"
                value="Other"
                onChange={(e) => handleChange(e, 'goals', true)}
                checked={formData.goals.includes('Other')}
              />
              <label htmlFor="goalsOther">Other</label>
            </div>
            {formData.goals.includes('Other') && (
              <div className="field">
                <InputText
                  name="otherGoal"
                  value={formData.otherGoal || ''}
                  onChange={(e) => handleOtherChange(e, 'otherGoal')}
                  placeholder="Please specify"
                />
              </div>
            )}
          </div>

          {/* Question 7 */}
          <div className="field">
            <p>7. What is your favorite way to solve problems?</p>
            {[
              'Using logical reasoning and analysis',
              'Thinking creatively and brainstorming',
              'Experimenting and testing different solutions',
              'Discussing with others to get different perspectives',
            ].map((approach, index) => (
              <div key={index} className="field-radiobutton">
                <RadioButton
                  inputId={`problemSolvingApproach${index}`}
                  name="problemSolvingApproach"
                  value={approach}
                  onChange={(e) => handleChange(e, 'problemSolvingApproach')}
                  checked={formData.problemSolvingApproach === approach}
                />
                <label htmlFor={`problemSolvingApproach${index}`}>{approach}</label>
              </div>
            ))}
          </div>
        </div>

        {/* Right Column */}
        <div className="p-col-12 p-md-6">
          {/* Question 2 */}
          <div className="field">
            <p>2. What subjects interest you the most? (Select all that apply)</p>
            {['Mathematics', 'Science', 'Art', 'Music', 'Technology', 'History', 'Literature', 'Sports'].map((subject, index) => (
              <div key={index} className="field-checkbox">
                <Checkbox
                  inputId={`subjectInterest${index}`}
                  name="subjectInterests"
                  value={subject}
                  onChange={(e) => handleChange(e, 'subjectInterests', true)}
                  checked={formData.subjectInterests.includes(subject)}
                />
                <label htmlFor={`subjectInterest${index}`}>{subject}</label>
              </div>
            ))}
            <div className="field-checkbox">
              <Checkbox
                inputId="subjectInterestOther"
                name="subjectInterests"
                value="Other"
                onChange={(e) => handleChange(e, 'subjectInterests', true)}
                checked={formData.subjectInterests.includes('Other')}
              />
              <label htmlFor="subjectInterestOther">Other</label>
            </div>
            {formData.subjectInterests.includes('Other') && (
              <div className="field">
                <InputText
                  name="otherSubjectInterest"
                  value={formData.otherSubjectInterest || ''}
                  onChange={(e) => handleOtherChange(e, 'otherSubjectInterest')}
                  placeholder="Please specify"
                />
              </div>
            )}
          </div>

          {/* Question 4 */}
          <div className="field">
            <p>4. How do you prefer to work on assignments?</p>
            {['Individually', 'In small groups', 'With a mentor or tutor'].map((option, index) => (
              <div key={index} className="field-radiobutton">
                <RadioButton
                  inputId={`collaborationPreference${index}`}
                  name="collaborationPreference"
                  value={option}
                  onChange={(e) => handleChange(e, 'collaborationPreference')}
                  checked={formData.collaborationPreference === option}
                />
                <label htmlFor={`collaborationPreference${index}`}>{option}</label>
              </div>
            ))}
          </div>

          {/* Question 6 */}
          <div className="field">
            <p>6. Have you had any prior experience with programming or AI? If so, please describe briefly.</p>
            <InputTextarea
              name="priorExperience"
              value={formData.priorExperience}
              onChange={(e) => handleChange(e, 'priorExperience')}
              rows={3}
              cols={30}
            />
          </div>

          {/* Question 8 */}
          <div className="field">
            <p>8. Which topics in AI or machine learning are you most interested in? (Select all that apply)</p>
            {[
              'Robotics',
              'Natural Language Processing (NLP)',
              'Computer Vision',
              'Data Science',
              'Neural Networks',
              'Ethical AI',
              'AI in Gaming',
            ].map((topic, index) => (
              <div key={index} className="field-checkbox">
                <Checkbox
                  inputId={`aiTopicsInterest${index}`}
                  name="aiTopicsInterest"
                  value={topic}
                  onChange={(e) => handleChange(e, 'aiTopicsInterest', true)}
                  checked={formData.aiTopicsInterest.includes(topic)}
                />
                <label htmlFor={`aiTopicsInterest${index}`}>{topic}</label>
              </div>
            ))}
            <div className="field-checkbox">
              <Checkbox
                inputId="aiTopicsInterestOther"
                name="aiTopicsInterest"
                value="Other"
                onChange={(e) => handleChange(e, 'aiTopicsInterest', true)}
                checked={formData.aiTopicsInterest.includes('Other')}
              />
              <label htmlFor="aiTopicsInterestOther">Other</label>
            </div>
            {formData.aiTopicsInterest.includes('Other') && (
              <div className="field">
                <InputText
                  name="otherAiTopicInterest"
                  value={formData.otherAiTopicInterest || ''}
                  onChange={(e) => handleOtherChange(e, 'otherAiTopicInterest')}
                  placeholder="Please specify"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="p-mt-4">
        <Button type="submit" label="Submit" className="p-button" />
      </div>
    </form>
  );
};

export default DiagnosticForm;
