
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import BankDetails from '../components/content/details.json';  
import { Panel } from 'primereact/panel';
import SignUpFeatures from '../components/content/signup.json';
import { Badge } from 'primereact/badge';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SignUpService } from './services/signup.service';
import { Toast } from 'primereact/toast';
// interface for the props of the component
interface SignUpProps {
    onSignUp: (form: SignUpForm) => void;
}

// interface for the form data
interface SignUpForm {
    name: string;
    email: string;
    cell: string;
    childName: string;
    childAge: string;
}
interface BankDetails {
    name: string;
    accountNumber: string;
    branchCode: string;
    accountType: string;
    accountHolder: string;
}

interface BankDetailsTableProps {
    bankDetails: BankDetails;
}

const BankDetailsTable: React.FC<BankDetailsTableProps> = ({ bankDetails }) => {
    // Convert the bankDetails object into an array of key-value pairs
    const bankDetailsArray = Object.entries(bankDetails).map(([key, value]) => ({ key, value }));

    return (
        <div>
            <DataTable value={bankDetailsArray}>
                <Column field="key" header="Field" />
                <Column field="value" header="Value" />
            </DataTable>
        </div>
    );
};
// functional component
const SignUp: React.FC<SignUpProps> = ({ onSignUp }) => {
    const signUpService = new SignUpService();

    const [form, setForm] = useState<SignUpForm>({
        name: '',
        email: '',
        cell: '',
        childName: '',
        childAge: '0',
    });
    const [_signUpContent, setSignUpContent] = useState<ReactNode[]>([]);
    const [_submitted, setSubmitted] = useState<boolean>(false);
    const [_message, setMessage] = useState<string>("Step One: Fill our Details Below");
    const toast = useRef<Toast>(null);
    const [_signUpContentMobile, setSignUpContentMobile] = useState<ReactNode[]>([]);

    // state for the form data
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [id]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // lets validate that we have values for all inputs in the form
        if (!form.name || !form.email || !form.cell || !form.childName || !form.childAge) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Please fill in all fields', life: 3000 });
            return;
        }

        try {
            const response = await signUpService.submitSignup(form);
            if (response.ok) {
                toast.current?.show({ severity: 'info', summary: 'Info', detail: 'Sign-up successful', life: 3000 });
                setSubmitted(true);
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred during sign-up. Please contact support@codeplant.co.za to resolve this issue.', life: 5000 });
             
            }
        } catch (error) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred during sign-up. Please contact support@codeplant.co.za to resolve this issue.', life: 5000 });
        }
    };

    useEffect(() => {

        const features = SignUpFeatures.features.map((feature: any, index: number) => (
            <div key={index} style={{display: 'flex', width: '100%', textAlign: 'center', marginLeft: '5%'}}>
                <div style={{width: '10%'}} className='largeIcon'><i className={feature.icon} style={{fontSize: '50px', marginTop: 15, color: "#35A933"}}></i></div>
                <div style={{width: '80%', textAlign: 'left'}}>
                    <h3>{feature.name}</h3>
                    <p style={{width: '80%', textAlign: 'justify'}}>{feature.description}</p></div>
            </div>
        ))

        const _mobileFeatures = SignUpFeatures.features.map((feature: any, index: number) => (
            <div key={index} style={{display: 'flex', width: '100%', textAlign: 'center', marginLeft: '5%'}}>
                <div style={{width: '10%'}} className='largeIcon'><i className={feature.icon} style={{fontSize: '50px', marginTop: 15, color: "#35A933"}}></i></div>
                <div style={{width: '80%', textAlign: 'left'}}>
                    <h3>{feature.name}</h3>
                    <p style={{width: '80%', textAlign: 'justify'}}>{feature.description}</p></div>
            </div>
        ))

        const instructions = <p>{SignUpFeatures.instructions}</p>;
        setMessage("Step One: Fill out Details Below and submit");
        setSignUpContent([features,instructions]);
        setSignUpContentMobile([_mobileFeatures,instructions]);
    }, []);

    const _header = () => {
       return ( <div className='largeImage'><img src={SignUpFeatures.image} style={{width: '100%', borderRadius: '25px'}} /></div>  )
    }

    return (
        <Panel  header={!_submitted ? _header() : null} style={{width: '100%'}}>
            <Toast ref={toast} />
        <Card >

        {!_submitted ? <div className="pitchContent" style={{width: '100%'}}>{_signUpContent}</div> : null }
            
        {!_submitted ? <div className="pitchContentMobile" style={{width: '100%'}}>{_signUpContentMobile}</div> : null }

        <Panel header={_message} style={{width: '100%', textAlign: 'left'}}>
        {!_submitted ? <form onSubmit={handleSubmit}>
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="name">Name</label>
                        <InputText id="name" value={form.name} onChange={handleChange} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="email">Email</label>
                        <InputText id="email" value={form.email} onChange={handleChange} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="cell">Cell</label>
                        <InputText id="cell"  value={form.cell} onChange={handleChange} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="childName">Child's Name</label>
                        <InputText id="childName" value={form.childName} onChange={handleChange} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="childAge">Child's Age</label>
                        <InputText id="childAge" value={form.childAge} onChange={handleChange} />
                    </div>
                    <hr />
                    
           
                    <Button icon="pi pi-sparkles" iconPos="left" onClick={handleSubmit} label="Submit" style={{backgroundColor: "#35A933", color: "white"}}/>
                </div>
            </form> : <div>
            <BankDetailsTable bankDetails={BankDetails.bankDetails} />
            <p>After successful payment, send Proof of Payment to {BankDetails.contactEmail} and you will get a confirmation email with all of the information needed to access our online environment and Discord weekly classes.</p>
                </div>}
            </Panel>
        </Card>
        </Panel>
    );
}


export default SignUp;
