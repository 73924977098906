import React, { useEffect, useRef, useState } from "react";
import SignUp from "./sign-up";
import SignUpMobile  from "./sign-up-mobile";
import logo from "./images/codeplant_images/logo_med.png";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import NewsDataView from "./news";
import Footer from "./footer";
import Slider from "./slider";
import { Dock } from "primereact/dock";
import { MenuItem } from "primereact/menuitem";
import { Tooltip } from "primereact/tooltip";
import Environments from "./environments";
import { Card } from "primereact/card";
import LearningEnvironments from "../components/content/environments.json";
import ContentSections from "../components/content/sections.json";
import ContactForm from "./contact-us";
import FutureOfJobsReport from "./research";
import ImageGallery from "./images";
import LandingView from "./landing";
import AiClub from "./ai-club";
import { CodeMonkey } from "./code-monkey";
import { Sidebar } from 'primereact/sidebar';
import { Carousel } from "primereact/carousel";
import LearningEnvironmentsMobile from "./environments-mobile";
import { Image } from "primereact/image";
import JoinAIClubPromo from "./join-ai-promo";
import Monkey from "./monkey";


const HomePage = () => {
  const mainHolderRef = useRef<HTMLDivElement>(null);
  const [_contentModal, setContentModal] = useState(false);
  const [monkeyVisible, setMonkeyVisible] = useState(false);
  const [_isOpen, setIsOpen] = useState(false);
  const [_message, setMessage] = useState<any>(
    <div style={{display: 'flex', textAlign: 'center'}}>
      <div style={{width: '45%'}}><img src={logo} width="50" height="50" /></div>
     <p>What do you get for signing up?</p>
    </div>
  );
  const _handleSignUp = () => {
    // setIsOpen(false)
    setMessage(
      <>
        <img src={logo} width="70" />
        &nbsp;&nbsp;&nbsp;Thank you for signing up?
      </>
    );
  };
  const [selectedEnvironment, setSelectedEnvironment] = useState<any>();
  const [isHome, setIsHome] = useState(true);
  const [content, setContent] = useState<any>();
  const [wakeText, setWakeText] = useState("Wake the Code Monkey");

  const _contactCallback = (data: any) => {
    console.log(data);
  }




  useEffect(() => {
    _handleContent("home");

  }, []);

  const handleScrollToDiv = () => {
    if (mainHolderRef.current) {
      window.scrollTo({
        top: mainHolderRef.current.offsetTop - 110,
        left: 0,
        behavior: "smooth",
      });

    }
  };

  const _handleMenuItem = (id: string) => {
    _handleContent(id);
    handleScrollToDiv();
  };
  const start = () => {
    return (
      <div style={{ margin: 0, padding: 0 }}>
        <Tooltip target=".custom-tooltip-btn">
          <p> <i className={`pi pi-${monkeyVisible ? 'moon' : 'bell'}`}></i>&nbsp;&nbsp;{wakeText}</p>
        </Tooltip>
        <Button 
        className="custom-tooltip-btn"
        tooltipOptions={{position: 'bottom'}}
        style={{backgroundColor: 'white',border: 'none', padding: 0, marginRight: 20}}
        onClick={()=>{
          setWakeText(monkeyVisible ? "Wake Code Monkey" : "Put Code Monkey to Sleep");
          setMonkeyVisible(!monkeyVisible);
        }}
        ><img src="code-monkey/resized/monkey2-small-smile.png" width="75" /></Button>
        <Button
          label="Home"
          outlined
          className="mainButton"
          icon="pi pi-home"
          onClick={() => {
            _handleMenuItem("home");
          }}
        />
        <Button
          label="Ai Club"
          outlined
          className="mainButton"
          icon="pi pi-sparkles"
          onClick={() => {
            _handleMenuItem("ai-club");
          }}
        />
        {/* <Button
          label="Courses"
          outlined
          className="mainButton"
          icon="pi pi-graduation-cap"
          onClick={() => {
            _handleMenuItem("courses");
          }}
        /> */}
        <Button
          label="Photos"
          className="mainButton"

          outlined
          icon="pi pi-images"
          onClick={() => {
            _handleMenuItem("photos");
          }}
        />
        <Button
          label="Research"
          
          outlined
          icon="pi pi-book"
          className="mainButton"

          onClick={() => {
            _handleMenuItem("research");
          }}
        />
        <Button
          label="Contact Us"
          outlined
          className="mainButton"

          icon="pi pi-envelope"
          onClick={() => {
            _handleMenuItem("contact-us");
          }}
        />
      </div>
    );
  };

  const center = () => {
    return (
      <>
        <img src={logo} width="100" height="100" />
      </>
    );
  };

  const centerMobile = () => {
    return (
      <>
        <img src={logo} width="50" height="50" />
      </>
    );
  };

  const end = () => {
    return (
      <div>
        <Button
          label="Join the Ai Club!"
          outlined
          icon="pi pi-microchip-ai"
          size="small"
          onClick={() => {
            setIsOpen(true);
          }}
        />
      </div>
    );
  };



  const _videoData = [
    // { id: '1', title: 'I AI N - Demo', url: 'https://www.youtube.com/embed/b5e20bz-nV4' },
    // { id: '2', title: 'Blender Add City Addon Example', url: 'https://www.youtube.com/embed/jcGWrFqz5PI' },
    {
      id: "3",
      title: "HTML Tools",
      url: "https://www.youtube.com/embed/9csPNyPnDto",
    },
    {
      id: "4",
      title: "HTML Easter Card",
      url: "https://www.youtube.com/embed/oITrI3Ej0zY",
    },
    {
      id: "5",
      title: "Makecode Arcade pt 2",
      url: "https://www.youtube.com/embed/Qf4Bv9fj3ck",
    },
    {
      id: "6",
      title: "Codeplant Platform and Makecode Arcade",
      url: "https://www.youtube.com/embed/aepaG9_wJ60",
    },
    {
      id: "7",
      title: "HTML Crypto Art Gallery",
      url: "https://www.youtube.com/embed/tdSCiWeag54",
    },
    {
      id: "8",
      title: "Crypto-Art Part 2",
      url: "https://www.youtube.com/embed/NhRuv-B17Sk",
    },
    {
      id: "9",
      title: "Intro to Crypto-Art",
      url: "https://www.youtube.com/embed/uvl66jqoFTQ",
    },
    {
      id: "10",
      title: "Simple JS Game and Monkey Game pt 3",
      url: "https://www.youtube.com/embed/x_ftFsVU_Mc",
    },
    {
      id: "11",
      title: "Stream 10 - Monkey Game Pt2",
      url: "https://www.youtube.com/embed/YFxVrPWqOA0",
    },
    {
      id: "12",
      title: "First Javascript and Monkey Game",
      url: "https://www.youtube.com/embed/oZHUJNHbVCE",
    },
    {
      id: "13",
      title: "Micro:bit - Reaction Game",
      url: "https://www.youtube.com/embed/P35ruqrVF8M",
    },
    {
      id: "14",
      title: "HTML and Micro:bit",
      url: "https://www.youtube.com/embed/W3r16TJxUm4",
    },
    {
      id: "15",
      title: "Codeplant Live - Rock Paper Scissors",
      url: "https://www.youtube.com/embed/lGVgqdXjTV8",
    },
    {
      id: "16",
      title: "Rock Paper Scissors",
      url: "https://www.youtube.com/embed/48KefNoPQLQ",
    },
    {
      id: "17",
      title: "The Robot Game",
      url: "https://www.youtube.com/embed/ep3OvcfE2-c",
    },
    {
      id: "18",
      title: "The Code Editor",
      url: "https://www.youtube.com/embed/fdyhJ2NgegU",
    },
    {
      id: "19",
      title: "The Drone Game",
      url: "https://www.youtube.com/embed/MSDRn9Z4ABE",
    },
    {
      id: "20",
      title: "Codeplant Main Intro",
      url: "https://www.youtube.com/embed/dp6WZV4fccU",
    },
    {
      id: "21",
      title: "Commands and Arguments",
      url: "https://www.youtube.com/embed/Du4Cp_VjLKE",
    },
    {
      id: "22",
      title: "Commands and Arguments",
      url: "https://www.youtube.com/embed/tbxj2tQjzho",
    },
    {
      id: "23",
      title: "Codeplant Tools Introduction",
      url: "https://www.youtube.com/embed/cIbpNISH9Hk",
    },
  ];
  const RenderToolsSection = () => {
    return (

        <Environments environment={selectedEnvironment as any} 
        _environmentCb={(x: string)=>{
          _handleContent(x);
          handleScrollToDiv();
        }}
        _homeCb={(s?:string)=>{

          if(s == 'sign-up') {
            setIsOpen(true);

          } else {
          handleScrollToDiv();
          setIsHome(true);
          setSelectedEnvironment(null);
          }
        }}/>

    );
  };

  const RenderHomeSection = () => {
    return (
      <Card>{content}</Card>
    )

  };
  const [_selectedSection, setSelectedSection] = useState<'ai-club'|'home' | 'photos' | 'research' | 'contact-us' >();

  const _handleContent = (content: string) => {
    if (content === "courses" ||content === "home" || content === "ai-club" || content === "photos" || content === "research" || content === "contact-us") {
      setIsHome(true);
      setSelectedEnvironment(null);
      setSelectedSection(content as any);
      let _section = ContentSections.find((section:any) => section?.slug === content);
      if (_section) {
        setIsHome(true);

        switch(content) {
          case 'ai-club':
            setContent(<AiClub _signUp={_handleSignUp}  />);
            break;
          case 'home':
            setContent(<LandingView _cb={(x?: string)=>{
              if (x) {
                debugger
                _handleContent(x);
                handleScrollToDiv();
              } else {
                setIsOpen(true)
              }
              
            }}/>);
            break;
          case 'photos':
            setContent(<ImageGallery />);
            break;
          case 'research':
            setContent(<FutureOfJobsReport />);
            break;
          case 'contact-us':
            setContent(<ContactForm onSubmit={_contactCallback} />);
            break;
          case 'courses':
            setContent(_section.content);
            break;
          default:
            
            break;

        }
       
      }

      return <p>{content}</p>;

    }
  
    const _env = LearningEnvironments.find((env) => env.slug === content);
    if (_env) {
      setIsHome(false);
      setSelectedEnvironment(_env);
      return;
    }
  };
  
  const items: MenuItem[] = [
    {
      label: "Image Generator",
      url: "https://images.codeplant.co.za/",
      command: () => {
        _handleContent("image-generator");
        handleScrollToDiv();
      },
      icon: () => <img alt="Image Generator" src="/envs/resized/images.png" width="50" />,
      data: "/envs/resized/images.png"
    },

    {
      label: "Art Maker",
      url: "https://art-maker.codeplant.co.za/",
      command: () => {
        _handleContent("art-maker");
        handleScrollToDiv();
      },
      icon: () => <img alt="Art Maker" src="/envs/resized/art-maker.png" width="50" />,
      data: "/envs/resized/art-maker.png"
    },
    {
      label: "Boolean Paths",
      url: "https://booleans.codeplant.co.za/",
      command: () => {
        _handleContent("boolean-paths");
        handleScrollToDiv();
      },
      icon: () => (
        <img alt="Boolean Paths" src="/envs/resized/logic-logo.png" width="50" />
      ),
      data: "/envs/resized/logic-logo.png"
    },
    {
      label: "Tic Tac AI",
      url: "https://tic-tac-ai.codeplant.co.za/",
      command: () => {
        _handleContent("tic-tac-ai");
        handleScrollToDiv();
      },
      style: { margin: 0, padding: 0 },
      icon: () => <img alt="Tic Tac AI" src="/envs/resized/tic-tac.png" width="50" />,
      data: "/envs/resized/tic-tac.png"
    },
    {
      label: "Pixelizer",
      url: "https://pixelizer.codeplant.co.za/",
      command: () => {
        _handleContent("pixelizer");
        handleScrollToDiv();
      },
      icon: () => (
        <img alt="Pixelizer" src="/envs/resized/pixelixer-icon.png" width="50" />
      ),
      data: "/envs/resized/pixelixer-icon.png"
    },
    {
      label: "Model Playground",
      url: "https://playground.codeplant.co.za/",
      command: () => {
        _handleContent("model-playground");
        handleScrollToDiv();
      },
      icon: () => <img alt="Eno bot" src="/envs/resized/playground.png" width="50" />,
      data: "/envs/resized/playground.png"
    },
    {
      label: "Eno Bot",
      url: "https://eno.codeplant.co.za/",
      command: () => {
        _handleContent("eno-bot");
        handleScrollToDiv();
      },
      icon: () => <img alt="Eno bot" src="/envs/resized/eno.png" width="50" />,
      data: "/envs/resized/eno.png"
    },
    {
      label: "AI LoopStation",
      url: "https://loopstation.codeplant.co.za/",
      command: () => {
        _handleContent("ai-loopstation");
        handleScrollToDiv();
      },
      icon: () => (
        <img alt="AI LoopStation" src="/envs/resized/loopstation.png" width="50" />
      ),
      data: "/envs/resized/loopstation.png"
    },
    {
      label: "Mini ML",
      url: "https://mini-ml.codeplant.co.za/",
      command: () => {
        _handleContent("mini-ml");
        handleScrollToDiv();
      },
      icon: () => <img alt="Mini ML" src="/envs/resized/miniMl.png" width="50" />,
      data: "/envs/resized/miniMl.png"
    },


    {
      label: "Identity Mesh",
      url: "https://self-portrait.codeplant.co.za/",
      command: () => {
        _handleContent("identity-mesh");
        handleScrollToDiv();
      },
      icon: () => <img alt="Identity Mesh" src="/envs/resized/identity.png" width="50" />,
      data: "/envs/resized/identity.png"
    },
    {
      label: "AI Tutor",
      url: "https://ai.codeplant.co.za/",
      command: () => {
        _handleContent("ai-tutor");
        handleScrollToDiv();
      },
      icon: () => <img alt="AI Tutor" src="/envs/resized/tutor-logo.png" width="50" />,
      data: "/envs/resized/tutor-logo.png"
    },
    {
      label: "Maze Solver",
      url: "https://maze-solver.codeplant.co.za/",
      command: () => {
        _handleContent("maze-solver");
        handleScrollToDiv();
      },
      icon: () => <img alt="Maze Solver" src="/envs/resized/mouse.png" width="50" />,
      data: "/envs/resized/mouse.png"
    },
    {
      label: "Upsy Downsy",
      url: "https://upsy-downsy.codeplant.co.za/",
      command: () => {
        _handleContent("upsy-downsy");
        handleScrollToDiv();
      },
      icon: () => <img alt="Upsy Downsy" src="/envs/resized/upsy.png" width="50" />,
      data: "/envs/resized/upsy.png"
    },
  ];

  const _joinClub = () => {
    setIsOpen(true);
  };

  const _renderMainPanel = () => {

    return (
      <div style={{ display: "flex", flexDirection: "row", padding: "7px" }}>
        <div style={{ width: "20%", flex: "1 1 20%" }}>
          <NewsDataView />
          <br />
          {/* <VideoGallery videos={_videoData.reverse()} /> */}
        </div>
        <div style={{ width: "1%" }}></div>
        <div style={{ width: "79%", flex: "1 1 79%" }}>
          {selectedEnvironment && !isHome && <RenderToolsSection />}
          {!selectedEnvironment && isHome && <RenderHomeSection />}
        </div>
      </div>
    );
  };

  const _handleMonkeyResponse = (data: any) => {
    // code from the monkey
  }

  const _makeHeader = () => {
    return ( <>
    <img src="codeplant_images/logo_small.png" width="75" 
      style={{backgroundColor: 'white', border: '1px solid black'}}
      /><Button
      label="Join the Ai Club!"
      
      icon="pi pi-microchip-ai"
      size="large"
      onClick={() => {
        setMonkeyVisible(false);
        setIsOpen(true);
      }}
    />
    </>)
  }
  
  return (  
    <>
    <div id="desktopSite">
            <Sidebar 
            header={_makeHeader()}
              dismissable={false}
            visible={monkeyVisible} onHide={() => setMonkeyVisible(false)} style={{width: '25%', minWidth: '300px', backgroundColor: 'green'}}
              modal={false}
              closeOnEscape={true}

              >
                <CodeMonkey _cb={_handleMonkeyResponse}/>
                <br />

                <JoinAIClubPromo _cb={()=>{
                  setMonkeyVisible(false);
                  setIsOpen(true);
                }}/>
              <br />
              <div>
        <div style={{width: '100%', padding: '25px', textAlign: 'center'}}>
            <p>© 2024 Codeplant | All Rights Reserved</p>
        </div>
        <div style={{width: '100%', textAlign: 'center'}}>
            <h3>Contact Us</h3>
            <p><strong>Codeplant</strong></p>
            <p>+27 (72) 422 8644</p>
            <p><a href="mailto:support@codeplant.co.za">support@codeplant.co.za</a></p>
        </div>
       
    </div>
            </Sidebar>
    <div id="dockHolder">
        <Tooltip
          className="dark-tooltip"
          target=".dock-advanced .p-dock-action"
          position="left"
          showDelay={150}
        />

        <Dock
          model={items}
          position={"right"}
        />
      </div>
      <Toolbar
        className="headerBar"
        start={center}
        center={start}
        end={end}
        style={{ padding: "0px", paddingLeft: "20px", paddingRight: "30px" }}
      />

      {/* SLIDER */}
      <div className="sliderWrapper">
        <Slider _cb={_joinClub} />
      </div>
      
      {/* MAIN PANEL */}
      <div className="mainHolder">
        <div className="mainPanel" ref={mainHolderRef}>{_renderMainPanel()}</div>
      </div>
      {/* FOOTER */}
      <div className="footer">
        <Footer />
      </div>
      {/* DOCK  */}
      {/* SIGN UP DIALOG */}
      <Dialog
        header={_message}
        visible={_isOpen}
        className="modalWidth"
        onHide={() => setIsOpen(false)}
      >
        {/*  if mobile show signupmobile */}

      {window.innerWidth < 768 ? <SignUpMobile onSignUp={_handleSignUp} /> :
        <SignUp onSignUp={_handleSignUp} /> 
      }
      </Dialog>
    </div>
    <div id="mobileSite">
    <Toolbar
        start={centerMobile}
        end={end}
        style={{ padding: "0px", paddingLeft: "20px", paddingRight: "30px" }}
      />

    <CodeMonkey _cb={_handleMonkeyResponse}/>
    <hr />
    <div style={{padding: '20px', textAlign: 'justify'}}>
      <div style={{margin:'auto', width: '50%'}}>
      <Image src={logo} width="100%"/>
      </div>
      
    <h5 style={{textAlign: 'center'}}>Codeplant Learning Environments</h5>

    <p>
        The Codeplant platform offers a diverse collection of engaging, interactive simulations that break down advanced technological concepts, making them accessible and easy to grasp. Explore the different environments below to learn more about each one in detail.
        </p>
    </div>
    <Carousel value={items} numVisible={3} numScroll={1}  circular style={{width: '100%', height: '100%'}} itemTemplate={(eachItem)=>{
      return <><img src={eachItem.data} width="70"  id={eachItem.label} onClick={(e)=>{
       setContentModal(true);
       const _env = LearningEnvironments.find((env) => env.title === eachItem.label);
        if (_env) {
          setSelectedEnvironment(_env);
        }
      }}/></>
    }}>

    </Carousel>

    <hr />
<div style={{width: '100%', backgroundColor: 'green', padding: '10%', color: 'white'}}>
  <i className="pi pi-desktop" style={{fontSize: '2em'}}></i>
    <p>Be sure to also visit Codeplant's website on a desktop device to view more information and cool features!</p>
</div>


    <hr />


    <Dialog
       header={selectedEnvironment?.title}
        visible={_contentModal}
        style={{ width: "100vw", height: "100vh" }}
        onHide={() => setContentModal(false)}
      >
        <LearningEnvironmentsMobile environment={selectedEnvironment as any} 
        _environmentCb={(x: string)=>{
        
        }}
        _homeCb={()=>{

          setContentModal(false);

        }}/>
    </Dialog>
    <Footer />
      </div>
    </>
  );
};

export default HomePage;