import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import FutureOfJobsReport from "./future-of-jobs";
import Timeline from "./timeline";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { Panel } from "primereact/panel";

interface ResearchSection {
  name: string;
  component: React.ReactNode;
  image: string;
  icon: string;
  description: string;
}
const Research: React.FC = () => {
  const [_selectedResearch, setSelectedResearch] =
    React.useState<ResearchSection>();
  const [_isOpen, setIsOpen] = React.useState<boolean>(false);
  const _research: ResearchSection[] = [
    {
      name: "Future of Jobs Report 2023",
      component: <FutureOfJobsReport />,
      image: "futureOfWork.png",
      icon: "pi pi-briefcase",
      description:
        "The Future of Jobs Report 2023 aims to shed light on the pandemic’s impact on the global employment landscape and provide insights into the trends that are shaping the future of work.",
    },
    {
      name: "History of Ai Timeline",
      component: <Timeline />,
      image: "timeline.png",
      icon: "pi pi-microchip-ai",
      description:
        "The Codeplant History of AI Timeline is an interactive timeline of AI that spans from the beginning of the development of the ideas that allowed digital communications and eventually the development od Ai.",
    },
  ];

  return (
    <div>
      <div style={{ width: "80%", margin: "auto", textAlign: "center" }}>
        <p>
          Codeplant has been inspired by the wonderful research that has gone
          into the area of Artificial Intelligence. In this section we make
          available some key reports and papers that have shaped our mission and
          methodology. Click on a link below to see more about each topic.
        </p>
        <hr />
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "50px",
          padding: "10px",
        }}
      >
        {_research.map((research, index) => (
          <Card
            header={
              <Image
                imageClassName="rounded"
                src={`${research.image}`}
                alt={research.name}
                style={{ width: "100%", height: "200px", borderRadius: "20px" }}
              />
            }
            key={index}
            style={{ maxWidth: "35%", margin: "auto" }}
            className="p-shadow-24"
          >
            <div style={{ display: "flex", padding: 10 }}>
              <div
                className="p-d-flex p-jc-center"
                style={{ width: "30%", margin: "auto" }}
              >
                <i
                  className={research.icon}
                  style={{ fontSize: "4em", color: "#118c38" }}
                ></i>
              </div>
              <div style={{ width: "60%", textAlign: "justify" }}>
                <h4>{research.name}</h4>
                <p>{research.description}</p>
              </div>
            </div>
            <br />

            <div className="p-d-flex p-jc-center">
              <Button
                label="View"
                icon="pi pi-eye"
                style={{ width: "80%" }}
                onClick={() => {
                  setSelectedResearch(research);
                  setIsOpen(true);
                }}
              />
            </div>
          </Card>
        ))}


        <Dialog
          header={_selectedResearch?.name}
          visible={_isOpen}
          className="modalWidth"
          onHide={() => setIsOpen(false)}
        >
          {_selectedResearch && _selectedResearch.component}
        </Dialog>
      </div>
    </div>
  );
};

export default Research;
