import React, { useEffect, useState } from 'react';
import { Image } from 'primereact/image';

interface MonkeyProps {
    _imageChange: string
}
const AdminMonkey = (props: MonkeyProps) => {

    const imagesData = {
        images: [
            '/monkey-admin/2/resized/1.png',
            '/monkey-admin/2/resized/2.png',
            '/monkey-admin/2/resized/3.png',
            '/monkey-admin/2/resized/4.png',
            '/monkey-admin/2/resized/5.png',
            '/monkey-admin/2/resized/6.png',
            '/monkey-admin/2/resized/7.png',
            '/monkey-admin/2/resized/8.png',
            '/monkey-admin/2/resized/9.png',
            '/monkey-admin/2/resized/10.png'
        ]
    }

    const [currentImage, setCurrentImage] = useState<string>(imagesData.images[Math.random() * imagesData.images.length | 0]);

    useEffect(() => {
       setCurrentImage(imagesData.images[Math.random() * imagesData.images.length | 0]);
    }, [props._imageChange]);

    
    return (
  
            <Image src={`${currentImage}`} alt="Monkey Image" width="100" imageStyle={{borderRadius: '15px', boxShadow: '1px 1px 1px 1px rgba(20,20,20,0.6)'}}/>

    );
};

export default AdminMonkey;
