import React from "react";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import News from "./content/news.json";
import { Panel } from "primereact/panel";
import { ScrollPanel } from "primereact/scrollpanel";

interface NewsItem {
  title: string;
  content: string;
  date: string;
  link: string;
  image: string;
  icon: string;
}

const NewsDataView: React.FC = () => {
    
  const itemTemplate = (item: NewsItem) => {
    return (
      <Card
        title={item.title}
        subTitle={item.date}
        style={{ width: "100%", marginBottom: "10px" }}
        header={<img alt={item.title} src={item.image} style={{borderRadius: 10}} />}
      >
        <p>{item.content}</p>
        {item.link ? (
          <Button
            label="Read More"
            icon="pi pi-external-link"
            onClick={() => window.open(item.link, "_blank")}
            style={{ backgroundColor: "#118C38", color: "white" }}
          />
        ) : (
          <i
            className={item.icon}
            style={{ fontSize: "2em", color: "#118C38" }}
          ></i>
        )}
      </Card>
    );
  };

  const headerTemplate = () => {
    return (
      <div className="panelHeader">
        <i className="pi pi-star" style={{ fontSize: 25, color: "white" }}></i>
        &nbsp;&nbsp;NEWS
      </div>
    );
  };

  return (
    <Panel headerTemplate={headerTemplate()}>
      <ScrollPanel
        title={"NEWS"}
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <DataView value={News} layout="list" itemTemplate={itemTemplate} />

      </ScrollPanel>
    </Panel>
  );
};

export default NewsDataView;
